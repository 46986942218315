/*eslint-disable*/
import React, { useEffect } from 'react'
import Header from './Header'
import BgHeader from './BgHeader'
import Bottom from './Bottom'
import LeftMenu from './LeftMenu'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import {
  useAccount,
  useEnsName,
  useSignMessage,
  useContract,
  useConnect,
} from 'wagmi'
import { show, clearSign, setNewSign } from '../store/modules/signStore'
import { PostExistUserApi, PostSignApi } from '../request/api'
import { message } from 'antd'
import { InjectedConnector } from 'wagmi/connectors/injected'
function Layout () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { address, connector: activeConnector, isConnected } = useAccount()
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect({
      connector: new InjectedConnector(),
    })

  useEffect(() => {
    // console.log('address', address, connectors[0], isLoading, pendingConnector, isConnected)
    connect(connectors[0])
    if (location.pathname === '/') {
      navigate('/home')
    }
  }, [])

  const clearSign_ = clearSign()

  const { data, signMessage, isError, isSuccess, signMessageAsync } =
    useSignMessage({
      message: 'Welcome to FVC !',
    })
  const PostExistUser = async () => {
    try {
      const res = await PostExistUserApi({ address: address })
      if (res.data.code === 200) {
        if (res.data.status) {
          signMessageAsync()
            .then(async (res) => {
              console.log(res)
              const setSign = setNewSign({ address: address, sign: res })
              // dispatch(setSign)
              const signRes = await PostSignApi({
                address: address,
                signed: res,
              })
              if (signRes.data.code === 200) {
                console.log(signRes.data.status)
                if (signRes.data.status) {
                  console.log(res)
                  localStorage.setItem('token', signRes.data.data.token)
                  localStorage.setItem('address', address)
                  // 1. 生成action对象

                  // 2. 提交action进行数据更新
                  dispatch(setSign)
                  message.success(t('signSuccess'))
                  window.location.reload()
                } else {
                  message.error(t('signError'))
                  // message.error(signRes.data.msg);
                }
              } else {
                message.error(t('signError'))
              }
            })
            .catch((error) => {
              message.error(t('signError'))
              console.log(error)
            })
        } else {
          if (location.pathname === '/home') {
          } else {
            navigate('/home')
          }
        }
      } else {
        message.error(res.data.msg)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const IsLeftMenu = [
    '/home',
    '/homeCreationTogether',
    '/nft',
    '/myAssets',
    '/myCom',
    '/buildingTheEarthTogether',
  ]
  const IsHeader = [
    '/home',
    // '/homeCreationTogether',
    // '/nft',
    // '/myAssets',
    // '/myCom',
    '/buildingTheEarthTogether',
  ]
  useEffect(() => {
    // dispatch(clearSign_)
    // localStorage.removeItem("token");
    // localStorage.removeItem("address");

    if (address) {
      if (
        localStorage.getItem('address') != address ||
        !localStorage.getItem('token')
      ) {
        // console.log('222222')

        // 2. 提交action进行数据更新
        dispatch(clearSign_)
        localStorage.removeItem('token') && localStorage.removeItem('address')
        PostExistUser()
      }
    }
  }, [address])
  return (
    <div className="min-h-screen bg-[#1E2346]">
      {/* {IsLeftMenu.includes(location.pathname) ? <LeftMenu ></LeftMenu> : ''} */}
      {IsHeader.includes(location.pathname) ? <BgHeader></BgHeader> : ''}
      <main>
        {' '}
        <Outlet />
      </main>
      {/* <Bottom></Bottom> */}
    </div>
  )
}
export default Layout
