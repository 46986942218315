/*eslint-disable*/
import React, { lazy, Suspense } from 'react'

import Layout from '../components/Layout'
import { getToken } from '../utils/token.js'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import Loading from './Loading'
const Home = lazy(() => import('../page/Home'))
const Person = lazy(() => import('../page/Person'))
const HomeCreationTogether = lazy(() => import('../page/HomeCreationTogether'))
const MyAssets = lazy(() => import('../page/MyAssets'))
const NFT = lazy(() => import('../page/Nft'))
const AssetsRecord = lazy(() => import('../page/Record/AssetsRecord'))
const USDTDetail = lazy(() => import('../page/Record/USDTDetail'))
const PCDDetail = lazy(() => import('../page/Record/PCDDetail'))
const CAKEDetail = lazy(() => import('../page/Record/CAKEDetail'))
const DOTDetail = lazy(() => import('../page/Record/DOTDetail'))
const FVCDetail = lazy(() => import('../page/Record/FVCDetail'))
const FVSDetail = lazy(() => import('../page/Record/FVSDetail'))
const MyCom = lazy(() => import('../page/MyCom'))
const DirectPushRecord = lazy(() => import('../page/Record/DirectPushRecord'))
const Proposal = lazy(() => import('../page/Proposal'))
const Vote = lazy(() => import('../page/Vote'))
const VoteInner = lazy(() => import('../page/VoteInner'))
// const BuildingTheEarthTogether = lazy(() =>
//   import('../page/BuildingTheEarthTogether')
// )
const TeamCreatesValueTogether = lazy(() =>
  import('../page/Record/TeamCreatesValueTogether')
)
const TeamNums = lazy(() => import('../page/Record/TeamNums'))
const TeamDataLP = lazy(() => import('../page/Record/TeamDataLP'))
const ALDDetail = lazy(() => import('../page/Record/ALDDetail'))
const ADADetail = lazy(() => import('../page/Record/ADADetail'))
const LINKDetail = lazy(() => import('../page/Record/LINKDetail'))
const Dividends = lazy(() => import('../page/Dividend'))
const AdaDividend = lazy(() => import('../page/AdaDividend'))
const GoldDirectPushRecord = lazy(() =>
  import('../page/Record/GoldDirectPushRecord')
)
const ComputingPowerIncome = lazy(() => import('../page/ComputingPowerIncome'))

const PowerFvcIncome = lazy(() => import('../page/Record/PowerFvcIncome'))
const PowerFvsIncome = lazy(() => import('../page/Record/PowerFvsIncome'))
const PowerRecommendIncome = lazy(() =>
  import('../page/Record/PowerRecommendIncome')
)
const PowerTeamIncome = lazy(() => import('../page/Record/PowerTeamIncome'))
const RecommendPowerList = lazy(() =>
  import('../page/Record/RecommendPowerList')
)
const TeamPowerList = lazy(() => import('../page/Record/TeamPowerList'))

const FVCPrice = lazy(() => import('../page/Record/FVCPrice'))

const FVSPrice = lazy(() => import('../page/Record/FVSPrice'))

const BlindBox = lazy(() => import('../page/Blindbox.jsx'))

const Mine = lazy(() => import('../page/Mine.jsx'))
// import Person from '../page/Person'
// import HomeCreationTogether from '../page/HomeCreationTogether'
// import MyAssets from '../page/MyAssets'
// import NFT from '../page/Nft'
// import AssetsRecord from '../page/Record/AssetsRecord'
// import USDTDetail from '../page/Record/USDTDetail'
// import PCDDetail from '../page/Record/PCDDetail.jsx'
// import CAKEDetail from '../page/Record/CAKEDetail.jsx'
// import DOTDetail from '../page/Record/DOTDetail.jsx'
// import MyCom from '../page/MyCom'
// import DirectPushRecord from '../page/Record/DirectPushRecord'
// import BuildingTheEarthTogether from '../page/BuildingTheEarthTogether'
// import TeamCreatesValueTogether from '../page/Record/TeamCreatesValueTogether'
// import TeamNums from '../page/Record/TeamNums'

function RequireAuth ({ children }) {
  const authed = getToken()
  return authed ? ( // 判断 authed 中登录状态是否为 true
    children // 嵌套传的
  ) : (
    <Navigate to="/login" replace /> // 跳转到登录
  )
}
const BaseRouter = () => (
  <Router>
    <Routes>
      {/* <Route path="/home" element={<Home />} />  */}
      <Route path="/" element={<Layout />}>
        <Route
          path="/home"
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        {/* <Route
          path="/homeCreationTogether"
          element={
            <Suspense fallback={<Loading />}>
              <HomeCreationTogether />
            </Suspense>
          }
        /> */}
        <Route
          path="/myAssets"
          element={
            <Suspense fallback={<Loading />}>
              <MyAssets />
            </Suspense>
          }
        />
        {/* <Route
          path="/nft"
          element={
            <Suspense fallback={<Loading />}>
              <NFT />
            </Suspense>
          }
        /> */}
        <Route
          path="/assetsRecord"
          element={
            <Suspense fallback={<Loading />}>
              <AssetsRecord />
            </Suspense>
          }
        />
        <Route
          path="/usdtDetail"
          element={
            <Suspense fallback={<Loading />}>
              <USDTDetail />
            </Suspense>
          }
        />
        {/* <Route
          path="/pcdDetail"
          element={
            <Suspense fallback={<Loading />}>
              <PCDDetail />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/cakeDetail"
          element={
            <Suspense fallback={<Loading />}>
              <CAKEDetail />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/dotDetail"
          element={
            <Suspense fallback={<Loading />}>
              <DOTDetail />
            </Suspense>
          }
        /> */}
        <Route
          path="/myCom"
          element={
            <Suspense fallback={<Loading />}>
              <MyCom />
            </Suspense>
          }
        />
        {/* <Route
          path="/directPushRecord"
          element={
            <Suspense fallback={<Loading />}>
              <DirectPushRecord />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/buildingTheEarthTogether"
          element={
            <Suspense fallback={<Loading />}>
              <BuildingTheEarthTogether />
            </Suspense>
          }
        /> */}
        <Route
          path="/teamCreatesValueTogether"
          element={
            <Suspense fallback={<Loading />}>
              <TeamCreatesValueTogether />
            </Suspense>
          }
        />
        <Route
          path="/teamNums"
          element={
            <Suspense fallback={<Loading />}>
              <TeamNums />
            </Suspense>
          }
        />
        {/* <Route
          path="/person"
          element={
            <Suspense fallback={<Loading />}>
              <Person />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/teamDataLP"
          element={
            <Suspense fallback={<Loading />}>
              <TeamDataLP />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/aldDetail"
          element={
            <Suspense fallback={<Loading />}>
              <ALDDetail />
            </Suspense>
          }
        />
        <Route
          path="/adaDetail"
          element={
            <Suspense fallback={<Loading />}>
              <ADADetail />
            </Suspense>
          }
        />
        <Route
          path="/linkDetail"
          element={
            <Suspense fallback={<Loading />}>
              <LINKDetail />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/proposal"
          element={
            <Suspense fallback={<Loading />}>
              <Proposal />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/vote"
          element={
            <Suspense fallback={<Loading />}>
              <Vote />
            </Suspense>
          }
        />
        <Route
          path="/voteInner/:id"
          element={
            <Suspense fallback={<Loading />}>
              <VoteInner />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/dividends"
          element={
            <Suspense fallback={<Loading />}>
              <Dividends />
            </Suspense>
          }
        /> */}
        {/* <Route
          path="/adaDividend"
          element={
            <Suspense fallback={<Loading />}>
              <AdaDividend />
            </Suspense>
          }
        /> */}
        <Route
          path="/goldDirectPushRecord"
          element={
            <Suspense fallback={<Loading />}>
              <GoldDirectPushRecord />
            </Suspense>
          }
        />
        <Route
          path="/fvcDetail"
          element={
            <Suspense fallback={<Loading />}>
              <FVCDetail />
            </Suspense>
          }
        />
        <Route
          path="/fvsDetail"
          element={
            <Suspense fallback={<Loading />}>
              <FVSDetail />
            </Suspense>
          }
        />
        <Route
          path="/computingPowerIncome"
          element={
            <Suspense fallback={<Loading />}>
              <ComputingPowerIncome />
            </Suspense>
          }
        />
        <Route
          path="/powerFvcIncome"
          element={
            <Suspense fallback={<Loading />}>
              <PowerFvcIncome />
            </Suspense>
          }
        />
        <Route
          path="/powerFvsIncome"
          element={
            <Suspense fallback={<Loading />}>
              <PowerFvsIncome />
            </Suspense>
          }
        />
        <Route
          path="/powerRecommendIncome"
          element={
            <Suspense fallback={<Loading />}>
              <PowerRecommendIncome />
            </Suspense>
          }
        />
        <Route
          path="/powerTeamIncome"
          element={
            <Suspense fallback={<Loading />}>
              <PowerTeamIncome />
            </Suspense>
          }
        />
        <Route
          path="/recommendPowerList"
          element={
            <Suspense fallback={<Loading />}>
              <RecommendPowerList />
            </Suspense>
          }
        />
        <Route
          path="/teamPowerList"
          element={
            <Suspense fallback={<Loading />}>
              <TeamPowerList />
            </Suspense>
          }
        />
        <Route
          path="/fvcPrice"
          element={
            <Suspense fallback={<Loading />}>
              <FVCPrice />
            </Suspense>
          }
        />
        <Route
          path="/fvsPrice"
          element={
            <Suspense fallback={<Loading />}>
              <FVSPrice />
            </Suspense>
          }
        />
        <Route
          path="/blindBox"
          element={
            <Suspense fallback={<Loading />}>
              <BlindBox />
            </Suspense>
          }
        />
        <Route
          path="/mine"
          element={
            <Suspense fallback={<Loading />}>
              <Mine />
            </Suspense>
          }
        />
        <Route
          path="/nft"
          element={
            <Suspense fallback={<Loading />}>
              <NFT />
            </Suspense>
          }
        />



      </Route>
    </Routes>
  </Router>
)

export default BaseRouter
