import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'normalize.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import BaseRouter from './router/index.jsx';
import { Provider } from 'react-redux'
import store from './store'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_US from "./locales/en-US.json";
import zh_CN from "./locales/zh-CN.json";
import zh_TW from "./locales/zh-TW.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import es from "./locales/es.json";
import pt from "./locales/pt.json";
import de from "./locales/de.json";
import fr from "./locales/fr.json";
import th from "./locales/th.json";
import ru from "./locales/ru.json";
import ar from "./locales/ar.json";

import 'normalize.css'

import { WagmiConfig, createConfig, configureChains, mainnet, } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { publicProvider } from 'wagmi/providers/public'
import { createPublicClient, http } from 'viem'
import { bscTestnet, bsc } from 'viem/chains';
import { StyleProvider } from '@ant-design/cssinjs';


// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [bscTestnet],
//   [publicProvider()],
// )
const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: bsc,
    // chain: bscTestnet, 
    transport: http()
  }),
})
// const config = createConfig({
//   autoConnect: true,
//   connectors: [
//     new InjectedConnector({ chains }),
//   ],
//   publicClient,
//   // webSocketPublicClient,
// })

i18n.use(initReactI18next).init({
  lng:
    localStorage.getItem("lang") == "zh_CN"
      ? "zh_CN"
      : (localStorage.getItem("lang") == "zh_TW"
        ? "zh_TW"
        : (localStorage.getItem("lang") == "ja"
          ? "ja" : (localStorage.getItem("lang") == "ko"
            ? "ko" : (localStorage.getItem("lang") == "es"
              ? "es" : (localStorage.getItem("lang") == "pt"
                ? "pt" : (localStorage.getItem("lang") == "de"
                  ? "de" : (localStorage.getItem("lang") == "fr"
                    ? "fr" : (localStorage.getItem("lang") == "th"
                      ? "th" : (localStorage.getItem("lang") == "ru"
                        ? "ru" : (localStorage.getItem("lang") == "ar"
                          ? "ar" : "en_US")))))))))),
  fallbackLng: "zh_CN",
  debug: false,
  resources: {
    en_US: {
      translation: en_US,
    },
    zh_CN: {
      translation: zh_CN,
    },
    zh_TW: {
      translation: zh_TW,
    },
    ja: {
      translation: ja,
    },
    ko: {
      translation: ko,
    },
    es: {
      translation: es,
    },
    pt: {
      translation: pt,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
    th: {
      translation: th,
    },
    ru: {
      translation: ru,
    },
    ar: {
      translation: ar,
    }
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <Provider store={store}>
        <StyleProvider hashPriority="high">
          <BaseRouter />
        </StyleProvider>
      </Provider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { store } //导出store