

import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk';
// import rootReducer from './reducers'; 
import counterStore from './modules/counterStore'
import leftStore from './modules/leftStore'
import drawerStore from './modules/drawerStore'
import useInfoStore from './modules/useInfoStore'
import logoutStore from './modules/logoutStore'
import withdrawModalStore from './modules/withdrawModalStore'
import bindInviteModalStore from './modules/bindInviteModalStore'
import rechangeModalStore from './modules/rechangeModalStore'
import signStore from './modules/signStore'
import changeInviteModalStore from './modules/changeInviteModalStore'
import socializeConfigStore from './modules/socializeConfigStore'
import mintModalStore from './modules/mintModalStore'
import langChangeStore from './modules/langChangeStore'
import fightGOModalStore from './modules/fightGOModalStore'
import langModalStore from './modules/langModalStore'

import homeNoticeStore from './modules/homeNoticeStore'
import addPowerModalStore from './modules/addPowerModalStore'
import switchModalStore from './modules/switchModalStore'
import transferModalStore from './modules/transferModalStore'
import switchNftModalStore from './modules/switchNftModalStore'
import openBlindBoxModalStore from './modules/openBlindBoxModalStore';
// const middleware = [...getDefaultMiddleware(), thunkMiddleware];
export default configureStore({
  reducer: {
    // 注册子模块
    counterStore,
    leftStore,
    drawerStore,
    useInfoStore,
    logoutStore,
    withdrawModalStore,
    bindInviteModalStore,
    rechangeModalStore,
    signStore,
    changeInviteModalStore,
    socializeConfigStore,
    mintModalStore,
    langChangeStore,
    fightGOModalStore,
    langModalStore,
    homeNoticeStore,
    addPowerModalStore,
    switchModalStore,
    transferModalStore,
    switchNftModalStore,
    openBlindBoxModalStore
  },
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunkMiddleware),
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), thunkMiddleware]

})