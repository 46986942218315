/*
 * @Author: huangzhibin huangzhibin_sq@163.com
 * @Date: 2024-01-01 21:14:06
 * @LastEditors: huangzhibin huangzhibin_sq@163.com
 * @LastEditTime: 2024-04-17 21:57:21
 * @FilePath: /fvc/src/components/Header.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
import HeaderLogoIcon from '../assets/images/header/headerlogo.png'
import EarthIcon from '../assets/images/header/earth.svg'
import { useAccount } from 'wagmi'
import { show as langModalShow } from '../store/modules/langModalStore'
import LangModal from './LangModal'
import { useSelector, useDispatch } from 'react-redux'
import { useClickAway, useBoolean } from 'ahooks'
export default function Header() {
  const { address } = useAccount()
  const dispatch = useDispatch()
  const [value, { toggle: onToggle, set, setTrue, setFalse: onFalse }] =
    useBoolean(false)
  return (
    <div className="bg-gradient-to-b from-[#1E2346] to-[#1E2346] h-[48px]">
      <LangModal></LangModal>
      <div className="flex justify-between h-[3rem] ml-2">
        <div className="flex">
          <img
            className=" w-[33.2px] h-[2.075rem]  mt-2"
            src={'/img/header/logo_big.png'}
            alt=""
          />
          <div className="ml-2 text-[16px] font-bold flex justify-center items-center">
            Free Metaverse
          </div>
        </div>
        <div className="flex items-center">
          <div>
            <button className="bg-gradient-to-b from-[#1C5384] to-[#569FD2] text-[#fff] font-bold rounded-xl px-3 h-[25px]">
              {address
                ? address?.replace(/(.{3}).{35}(.{4})/, '$1****$2')
                : 'Connect Wallet'}
            </button>
          </div>
          <div className="ml-3 mr-3">
            <div
              className="bg-gradient-to-b from-[#1C5384] to-[#569FD2] h-[25px] px-1 flex justify-center items-center rounded-md"
              onClick={() => {
                dispatch(langModalShow())
                onFalse()
              }}>
              <img className="w-[20px] h-[20px]" src={EarthIcon} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
