import { createSlice } from '@reduxjs/toolkit'

const fightGOModal = createSlice({
  // 模块名称独一无二
  name: 'fightGOModal',
  // 初始数据
  initialState: {
    show: false,
    fightGOInfo: {}
  },
  // 修改数据的同步方法
  reducers: {
    show (state, action) {

      state.show = !state.show
    },
    SaveInfo (state, action) {
      // console.log(state, action)
      state.fightGOInfo = action.payload
      // console.log(state.info) 
      // state.Info = state.Info
    }
  }
})

const { show, SaveInfo } = fightGOModal.actions
const reducer = fightGOModal.reducer

// 导出修改数据的函数
export { show, SaveInfo }
// 导出reducer
export default reducer