/*eslint-disable*/
import request from './request'

//提交绑定地址
export const BindRelationshipApi = (params) => request.post('/user/bind', params)

//提交购买ICO记录
// export const buyIDOApi = (params) => request.post('/user/buyIDO', params)

//获取ido基本信息
// export const getIdoRecordApi = (params) => request.get('/user/idoRecord', { params })

//提现
// export const PostWithdrawApi = (params) => request.post('/withdraw', params)

// //注册
// export const PostRegisterApi = (params) => request.post('/register', params)

// //登录
// export const PostLoginApi = (params) => request.post('/login', params)

// //签到
// export const PostCheckApi = (params) => request.post('/check', params)


//获取用户信息
export const getCoinApi = (params) => request.get('/getcoin', { params })


// //我的社区接口
// export const getTeamAwardApi = (params) => request.get('/team_award', { params })

//1.获取用户是否存在
export const PostExistUserApi = (params) => request.post('/user/exist_user', params)


//创建用户
export const PostCreateUserApi = (params) => request.post('/user/create', params)

//签名
export const PostSignApi = (params) => request.post('/signed/check_signed', params)


//激活用户
export const PostSetRefApi = (params) => request.post('/user/activation', params)

//个人中心数据
export const getUserInfoApi = (params) => request.get('/user/user_info', { params })

//获取NFT类型配置
export const getNftConfigApi = (params) => request.get('/nft/get_nft_config', { params })

//nft分红记录
export const getNftReceiveRecordApi = (params) => request.get('/nft/get_nft_receive_record', { params })







//lp分红记录
export const getLpReceiveApi = (params) => request.get('/record/get_lp_receive', { params })

//推荐奖励记录
export const getRecommendRecordApi = (params) => request.get('/record/get_recommend_record', { params })

//nft勋章分红记录
export const getNftReceiveApi = (params) => request.get('/record/get_nft_receive', { params })



//修改邀请码
export const PostUpCodeApi = (params) => request.post('/invitation_code/up_code', params)

//社交配置
export const getSocializeConfigApi = (params) => request.get('/socialize/get_socialize_config', { params })


//提现前验证后端数据余额是否足够
export const PostCheckBalanceApi = (params) => request.post('/balance/check_balance', params)

//铸造nft
export const PostMintApi = (params) => request.post('/nft/nft_casting', params)

//团队LP价值
export const GetAchievementApi = (params) => request.get('/team_achievement/get_achievement', { params })

//获取直推LP价值列表
export const GetDirectLpListApi = (params) => request.get('/team_achievement/get_direct_lp_list', { params })

//添加的LP列表
export const GetAddLpListApi = (params) => request.get('/lp/get_add_lp_list', { params })

//移除的LP列表
export const GetRemoveLpListApi = (params) => request.get('/lp/get_del_lp_list', { params })

//判断是否可以提现

export const GetIsWithdrawApi = (params) => request.get('/user/is_withdraw', { params })



//提交意见反馈
export const PostFeedbackApi = (params) => request.post('/feedback/feedback_operation', params)
//意见反馈列表
export const GetFeedbackListApi = (params) => request.get('/feedback/feedback_list', { params })

//获取预售记录
export const GetPreSaleRecordApi = (params) => request.get('/lp/get_lp_pre_sale_record', { params })

//获取预售配置
export const GetPreSaleConfigApi = (params) => request.get('/lp/get_pre_sale_lp_config', { params })

//lp预售操作
export const PostPreSaleApi = (params) => request.post('/lp/lp_pre_sale_operate', params)

//获取拼团配置
export const getGroupConfigApi = (params) => request.get('/group/get_group_config', { params })

//当前拼团参与记录
export const getCurrentGroupRecordApi = (params) => request.get('/group/current_group_record', { params })

//拼团操作
export const PostJoinGroupApi = (params) => request.post('/group/join_group', params)

//获取拼团记录
export const getGroupRecordApi = (params) => request.get('/group/group_record', { params })

//门票记录
export const getTicketRecordApi = (params) => request.get('/record/get_voucher_receive', { params })

//提现配置
export const GetWithdrawConfigApi = (params) => request.get('/withdraw_record/withdraw_config', { params })




//提现操作
export const PostWithdrawApi = (params) => request.post('/withdraw_record/withdraw', params)

//首页公告 
export const getHomeNoticeApi = (params) => request.get('/notice/get_notice', { params })


//获取家园共创数据
export const getMyCoCreateApi = (params) => request.get('/private/my_co_create', { params })

//私募操作
export const PostPlacementOperateApi = (params) => request.post('/private/placement_operate', params)

//nft分红记录
export const getMyNftDataApi = (params) => request.get('/nft/creation_nft', { params })

//资金记录
export const getMoneyDetailApi = (params) => request.get('/record/get_money_detail', { params })

//USDT记录
export const getUSDTDetailApi = (params) => request.get('/record/get_usdt_detail', { params })

//PCD记录
export const getGLVDetailApi = (params) => request.get('/record/get_glv_detail', { params })

//我的社区
export const getMyCommunityApi = (params) => request.get('/pledge/my_community', { params })

//直推列表
export const getDirectDetailApi = (params) => request.get('/record/get_direct_detail', { params })

//地球村数据
export const getEarthDataApi = (params) => request.get('/user/my_global_village', { params })

//我的共创值列表
export const getMyLpDetailApi = (params) => request.get('/record/get_my_lp_detail', { params })

//直推共创列表

export const getDirectCreateTogetherApi = (params) => request.get('/record/get_direct_create_together', { params })

//CAKE记录
export const getCAKEDetailApi = (params) => request.get('/record/get_cake_detail', { params })

//DOT记录
export const getDOTDetailApi = (params) => request.get('/record/get_pot_detail', { params })

//ADAD记录
export const getADADDetailApi = (params) => request.get('/record/get_adad_detail', { params })
//ADA记录
export const getADADetailApi = (params) => request.get('/record/get_ada_detail', { params })

//LINK记录
export const getLINKDetailApi = (params) => request.get('/record/get_link_detail', { params })

//我的团队算力列表
export const getTeamLPDataApi = (params) => request.get('/record/get_team_detail', { params })

//首页数据开关
export const getOnSwitchApi = (params) => request.get('/config/index_data_switch', { params })

//获取nft铸造配置
export const getNFTConfigApi = (params) => request.get('/nft/buy_nft_config', { params })

//铸造NFT
export const PostMintNFTApi = (params) => request.post('/nft/buy_nft', params)


//提案操作
export const postProposalOperateApi = (params) => request.post('/proposal/proposal_operate', params)
//提案列表
export const postProposalListApi = (params) => request.get('/proposal/proposal_list', { params })

//提案详情
export const getProposalDetailsApi = (params) => request.get('/proposal/proposal_details', { params })

//投票操作
export const postVoteOperateApi = (params) => request.post('/proposal/vote_operate', params)


//算力排行列表
export const getPowerRankingApi = (params) => request.get('/record/get_power_ranking', { params })

//分红本位配置
export const getGoldStandardApi = (params) => request.get('/gold_standard/get_config', { params })

//金本位
export const postGoldStandardInvestApi = (params) => request.post('/gold_standard/invest', params)

//金本位详情
export const getGoldStandardDetailstApi = (params) => request.get('/gold_standard/details', { params })

//金本位直推详情
export const getGoldStandardDirectPushApi = (params) => request.get('/gold_standard/direct_push', { params })

//闪兑
export const postPledgeExchangeApi = (params) => request.post('/pledge/exchange', params)


//fvc明细
export const getFvcDetailApi = (params) => request.get('/record/get_fvc_detail', { params })

//fvs明细
export const getFvsDetailApi = (params) => request.get('/record/get_fvs_detail', { params })

//算力收益详情
export const getPowerDetailApi = (params) => request.get('/pledge/power_details', { params })

//算力订单列表
export const getOrderListApi = (params) => request.get('/pledge/order_list', { params })

//燃烧
export const postCsombustionApi = (params) => request.post('/pledge/combustion', params)

//质押操作
export const postPledgeOperationApi = (params) => request.post('/pledge/pledge_operation', params)

//算力收益列表FVC
export const getPowerFvcIncomeApi = (params) => request.get('/record/get_power_fvc_income', { params })

//算力收益列表FVS
export const getPowerFvsIncomeApi = (params) => request.get('/record/get_power_fvs_income', { params })

//算力推荐收益列表
export const getPowerRecommendIncomeApi = (params) => request.get('/record/get_power_recommend_income', { params })

//算力节点收益列表
export const getPowerTeamIncomeApi = (params) => request.get('/record/get_power_team_income', { params })

//获取直推算力列表
export const getRecommendPowerListApi = (params) => request.get('/record/get_recommend_power_list', { params })

//获取团队算力列表
export const getTeamPowerListApi = (params) => request.get('/record/get_team_power_list', { params })


//转赠配置
export const getTransferConfigApi = (params) => request.get('/user/transfer_config', { params })

//转赠操作
export const postTransferApi = (params) => request.post('/user/transfer', params)

//首页数据
export const getUserIndexApi = (params) => request.get('/user/index', { params })


//币价数据
export const getIconDataApi = (params) => request.get('/currency_value/get_icon_data', { params })


//盲盒配置

export const getBlindconfigApi = (params) => request.get('/blind_box/get_config', { params })

//盲盒记录列表
export const getAllBlindBoxListApi = (params) => request.get('/blind_box/blind_box_page', { params })

//开盒
export const postOperateApi = (params) => request.post('/blind_box/participate', params)

//NFT卡牌兑换
export const postNftExchangeApi = (params) => request.post('/nft/nft_exchange', params)




//矿机配置
export const getStakingConfigApi = (params) => request.get('/mining/mining_config', { params })


//矿机订单列表
export const getMyOrderApi = (params) => request.get('/mining/mining_order', { params })


//购买矿机
export const postBuyMiningApi = (params) => request.post('/mining/buy_mining', params)



//NFT分红记录

//算力包配置
export const getPledgeConfigApi = (params) => request.get('/pledge/pledge_config', { params })










